import React, { useEffect } from 'react';
import { FormField } from '../inputField/textfield';
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddButton } from '../button/button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { getVendorList } from '../../redux/slices/vendorList';
import { useParams } from 'react-router-dom';
import { getProject } from '../../redux/slices/projectSlice/addProjectSlice';


interface VendorunallocationBodyProps {
    formDataArray: Array<{ vendor_id: string; task_count: string; type: string, status: string, new_vendor_id: string }>;
    setFormDataArray: React.Dispatch<React.SetStateAction<Array<{ vendor_id: string; task_count: string; type: string, status: string, new_vendor_id: string }>>>;
}

const VendorUnallocationBody: React.FC<VendorunallocationBodyProps> = ({ formDataArray, setFormDataArray }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams();
    const vendorList = useSelector((state: RootState) => state.vendorList.vendor?.results || []);
    const fetchPermissions = useSelector((state: RootState) => state.projects.permissions);
    useEffect(() => {
        dispatch(getVendorList({ paginate: true, project_id: id || '' }));
    }, [id, dispatch]);

    useEffect(() => {
        dispatch(getProject({ project_id: id || '' }));
    }, [id, dispatch]);

    const handleInputChange = (e: { target: { name: string; value: string } }, index: number) => {
        const { name, value } = e.target;
        const newFormDataArray = [...formDataArray];
        newFormDataArray[index] = {
            ...newFormDataArray[index],
            [name]: name === 'task_count' ? Number(value) : value,
        };
        setFormDataArray(newFormDataArray);
    };

    const handleSelectFieldChange = (event: SelectChangeEvent<string>, index: number, fieldName: string) => {
        const value = event.target.value;
        console.log(value)
        const newFormDataArray = [...formDataArray];
        newFormDataArray[index] = {
            ...newFormDataArray[index],
            [fieldName]: value,
        };
        setFormDataArray(newFormDataArray);
    };

    // const handleSelectNewVendorFieldChange = (event: SelectChangeEvent<string>, index: number, fieldName: string) => {
    //     const value = event.target.value;
    //     console.log(value);
    //     console.log({index})
    //     console.log({fieldName})
    //     const newDataArr = [...formDataArray];
    //     newDataArr[index] = {
    //         ...newDataArr[index],
    //         [fieldName]: value,
    //     };
    //     setFormDataArray(newDataArr);
    //     console.log(newDataArr)

    // };

    const handleSelectStatusChange = (event: SelectChangeEvent<string>, index: number, fieldName: string) => {
        const value = event.target.value
        const newDataArr = [...formDataArray]
        newDataArr[index] = {
            ...newDataArr[index],
            [fieldName]: value
        }
        setFormDataArray(newDataArr)
        console.log(newDataArr)
    }

    const handleAddMore = () => {
        setFormDataArray([...formDataArray, { vendor_id: '', task_count: '', type: '', status: '', new_vendor_id: '' }]);
    };

    const handleDelete = (index: number) => {
        const newFormDataArray = formDataArray.filter((_, i) => i !== index);
        setFormDataArray(newFormDataArray);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
    };
    return (
        <div style={{ padding: '40px', maxWidth: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
            <form onSubmit={handleSubmit}>
                {formDataArray.map((formData, index) => {
                    return (
                        <div key={index} style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '17.5%' }}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            lineHeight: '24px',
                                            textAlign: 'left',
                                            color: '#10383D',
                                        }}
                                    >
                                        Select Vendor
                                    </Typography>
                                    <Select
                                        name="vendor"
                                        value={formData.vendor_id}
                                        onChange={(e) => handleSelectFieldChange(e, index, 'vendor_id')}
                                        sx={{ width: '220px' }}
                                        size="small"
                                        displayEmpty
                                    >
                                        <MenuItem value="" disabled>
                                            Select Vendor
                                        </MenuItem>
                                        {
                                            vendorList
                                            .map((a: any) => {return { ...a, vendor_pseudo_name_small: (a.pseudo_name?.toLowerCase() || a.vendor_pseudo_name?.toLowerCase()) } })
                                            .filter(a => a.vendor_id != "")
                                            .sort((a, b) =>a.vendor_pseudo_name_small < b.vendor_pseudo_name_small ? -1 : a.vendor_pseudo_name_small > b.vendor_pseudo_name_small ? 1 : 0)
                                            .map((vendor: any) => {
                                                console.log({vendor})
                                                return (
                                                    <MenuItem key={vendor.vendor_id} value={vendor.vendor_id}>
                                                        {vendor.vendor_pseudo_name ? vendor.vendor_pseudo_name : vendor.pseudo_name}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '17.5%' }}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            lineHeight: '24px',
                                            textAlign: 'left',
                                            color: '#10383D',
                                        }}
                                    >
                                        Select Type
                                    </Typography>
                                    <Select
                                        name="type"
                                        value={formData.type}
                                        onChange={(e) => handleSelectFieldChange(e, index, 'type')}
                                        sx={{ width: '220px' }}
                                        size="small"
                                        displayEmpty
                                    >
                                        <MenuItem value="" disabled>
                                            Select Type
                                        </MenuItem>
                                        <MenuItem value="TASKING" disabled={fetchPermissions && (fetchPermissions.task_allocation_single?.[0] === 'false' || fetchPermissions.task_allocation_bulk?.[0] === 'false')}>Tasking</MenuItem>
                                        <MenuItem value="REVIEWING" disabled={fetchPermissions && (fetchPermissions.review_allocation_single?.[0] === 'false' || fetchPermissions.review_allocation_bulk?.[0] === 'false')}>Reviewing</MenuItem>
                                        {/* <MenuItem value="BOTH" disabled={fetchPermissions && (fetchPermissions.task_allocation_single?.[0] === 'false' || fetchPermissions.task_allocation_bulk?.[0] === 'false' || fetchPermissions.review_allocation_single?.[0] === 'false' || fetchPermissions.review_allocation_bulk?.[0] === 'false')}>Both</MenuItem> */}
                                    </Select>

                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '17.5%' }}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            lineHeight: '24px',
                                            textAlign: 'left',
                                            color: '#10383D',
                                        }}
                                    >
                                        New Vendor
                                    </Typography>
                                    <Select
                                        name="new_vendor_id"
                                        value={formData.new_vendor_id}
                                        onChange={(e) => handleSelectFieldChange(e, index, 'new_vendor_id')}
                                        sx={{ width: '220px' }}
                                        size="small"
                                        displayEmpty
                                    >
                                        <MenuItem value="" disabled>
                                            New Vendor
                                        </MenuItem>
                                        {vendorList.map((vendor: any) => (
                                            <MenuItem key={vendor.vendor_id} value={vendor.vendor_id}>
                                                {vendor.vendor_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '17.5%' }}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Montserrat',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            lineHeight: '24px',
                                            textAlign: 'left',
                                            color: '#10383D',
                                        }}
                                    >
                                        Status
                                    </Typography>
                                    <Select
                                        name="status"
                                        value={formData.status}
                                        onChange={(e) => handleSelectStatusChange(e, index, 'status')}
                                        sx={{ width: '220px' }}
                                        size="small"
                                        displayEmpty
                                    >
                                        <MenuItem value="" disabled>
                                            Select Status
                                        </MenuItem>
                                        <MenuItem value="ACTIVE">Active</MenuItem>
                                        <MenuItem value="COMPLETED">Completed</MenuItem>
                                        <MenuItem value="NOT_STARTED">Not Started</MenuItem>
                                        <MenuItem value="SUBMITTED">Submitted</MenuItem>
                                        <MenuItem value="QC_PUSHBACK">QC Pushback</MenuItem>
                                        <MenuItem value="QUALITY_REJECT">Quality Reject</MenuItem>
                                        <MenuItem value="AWAITING_TASK_UPDATE">Awaiting Task Update</MenuItem>
                                        <MenuItem value="AWAITING_REVIEW">Awaiting Review</MenuItem>
                                        <MenuItem value="AVAILABLE_TO_REVIEW">Available To Review</MenuItem>
                                        <MenuItem value="QUALITY_ACCEPTED">Quality Accepted</MenuItem>
                                        <MenuItem value="DELIVERED">Delivered</MenuItem>
                                        <MenuItem value="NOT_ALLOCATED">Not Allocated</MenuItem>

                                    </Select>

                                </div>
                                <FormField
                                    label="Task Count"
                                    placeholder="Enter Task Count"
                                    name="task_count"
                                    value={formData.task_count}
                                    onChange={(e) => handleInputChange(e, index)}
                                    textFieldSx={{ width: '220px' }}
                                />
                                <DeleteIcon
                                    sx={{ marginLeft: '-10px', color: '#ec480f', marginTop: '28px', cursor: 'pointer' }}
                                    onClick={() => handleDelete(index)}
                                />
                            </div>
                        </div>
                    )
                })}
                <AddButton onClick={handleAddMore}>Add More</AddButton>
            </form>
        </div>
    );
}

export default VendorUnallocationBody;